<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('incident_issue_form')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <!-- <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.firstName"
                                    label="First Name "
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.lastName"
                                    label="Last Name"
                                    required
                            ></v-text-field>
                        </v-col> -->
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.fullName"
                                    :label="$t('full_name')"
                                    dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.dateOfBirth"
                                            :label="$t('dob')"
                                            clearable
                                                  dense
                                    outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.nationality"
                                    :label="$t('nationality')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.country"
                                    :items="countries"
                                    :label="$t('country')"
                                    clearable
                                            dense
                                    outlined
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.state"
                                    :items="state"
                                    label="State"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.ageGroup" :items="PassengerTypes"
                                            item-value="value"
                                            dense
                                    outlined
                                            item-text="value"
                                            :label="$t('age_group')"></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.citizenShipNumber"
                                    :label="$t('citizenshipNumber')"
                                    required
                                          dense
                                    outlined
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.passportNumber"
                                    :label="$t('passport_number')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.licenseNumber"
                                    :label="$t('licenseNumber')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.otherID"
                                    :label="$t('otherID')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-img
                                    v-if="customerData.document"
                                    :src="customerData.document"
                                    contain
                                    height="50"
                            ></v-img>
                            <v-file-input
                                    v-model="customerData.document"
                                    show-size
                                          dense
                                    outlined
                                    :label="$t('documents')"
                            ></v-file-input>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      :label="$t('is_verified_document')"
                                      clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.issuedBy"
                                    :label="$t('issued_by')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.incidentType"
                                    :items="Services"
                                    :label="$t('incident_type')"
                                            dense
                                    outlined
                                    item-text="value"
                                    item-value="id"
                                    multiple
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.incidentName"
                                    :label="$t('incident_name')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    :items="personType"
                                    v-model="customerData.personType"
                                    :label="$t('person_type')"
                                            dense
                                    outlined
                                    required
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.incidentReportNumber"
                                    :label="$t('incident_report_number')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.sourceOfReport"
                                    :label="$t('source_of_report')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.issuedDate"
                                            :label="$t('issued_date')"
                                            clearable
                                            prepend-inner-icon="event"
                                            readonly
                                                  dense
                                    outlined
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="8"
                        >
                            <v-textarea
                                    rows="2"
                                        dense
                                    outlined
                                    v-model="customerData.remarks"
                                    :label="$t('remarks')"
                                    required
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerEditForm",
        data() {
            return {
                customerData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                AirPackages: [],
                PassengerTypes: [],
                FlightClasses: [],
                Issuer: ['E-Sewa'],
                personType: ["Child", "Adult"],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            editFormSubmit() {
                var formData = new FormData();
                formData.append("personInformationID", this.customerData.personInformationID);
                formData.append("branchModuleID", this.customerData.branchModuleID);
                formData.append("fullName", this.customerData.fullName);
                // formData.append("lastName", this.customerData.lastName);
                formData.append("gender", this.customerData.gender);
                formData.append("dateOfBirth", this.customerData.dateOfBirth);
                formData.append("ageGroup", this.customerData.ageGroup);
                formData.append("nationality", this.customerData.nationality);
                formData.append("country", this.customerData.country);
                formData.append("state", this.customerData.state);
                formData.append("citizenShipNumber", this.customerData.citizenShipNumber);
                formData.append("passportNumber", this.customerData.passportNumber);
                formData.append("licenseNumber", this.customerData.licenseNumber);
                formData.append("otherID", this.customerData.otherID);
                formData.append("document", this.customerData.document);
                formData.append("isVerifiedDocument", this.customerData.isVerifiedDocument);
                formData.append("isImportFromExcel", false);
                formData.append("incidentName", this.customerData.incidentName);
                formData.append("incidentType", this.customerData.incidentType);
                formData.append("personType", this.customerData.personType);
                formData.append("incidentReportNumber", this.customerData.incidentReportNumber);
                formData.append("sourceOfReport", this.customerData.sourceOfReport);
                formData.append("remarks", this.customerData.remarks);
                formData.append("issuedDate", this.customerData.issuedDate);
                formData.append("issuedBy", this.customerData.issuedBy);
                formData.append("organizationName", this.customerData.organizationName);
                formData.append("remarks", this.customerData.remarks);
                axios.post('Police/UpdateCustomerInformationAsync', formData)
                    .then((response) => {
                        if (response.data.success) {
                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Issue updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            formData() {
                axios.get("Shared/GetAgeGroupList").then(response => {
                    this.PassengerTypes = response.data;
                }).catch(error => console.log(error.response))
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
                axios.get("Police/DDLPoliceIncidentListAsync").then(response => {
                    this.Services = response.data;
                }).catch(error => console.log(error.response))
                this.getEditData().then(response => {
                    this.customerData = response.data
                    console.log(response.data)
                }).catch(err => console.log(err.response))
            },
            async getEditData() {
                return await axios.get('Police/GetCustomerInformationByIDAsync/' + this.editItemIndex)
            }
        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>